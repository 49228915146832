// Auto-generated language file from /opt/whdata/installedmodules/nubiumreviews.20250213T111221.868Z/webcomponents/index.lang.json
var registerTexts = require("@mod-tollium/js/gettid").registerTexts;
registerTexts("nubiumreviews","de",{"js":{"showless":"Zeige weniger","showmore":"Zeig mehr =>"}});
registerTexts("nubiumreviews","en",{"js":{"showless":"Show less","showmore":"Show more =>"}});
registerTexts("nubiumreviews","es",{"js":{"showless":"Muestra menos","showmore":"Ver el complejo =>"}});
registerTexts("nubiumreviews","fr",{"js":{"showless":"Toon minder","showmore":"Toon meer =>"}});
registerTexts("nubiumreviews","it",{"js":{"showless":"Toon minder","showmore":"Toon meer =>"}});
registerTexts("nubiumreviews","nl",{"js":{"showless":"Toon minder","showmore":"Toon meer =>"}});
// Adding dependency: /opt/whdata/installedmodules/nubiumreviews.20250213T111221.868Z/language/de.xml
// Adding dependency: /opt/whdata/installedmodules/nubiumreviews.20250213T111221.868Z/language/en.xml
// Adding dependency: /opt/whdata/installedmodules/nubiumreviews.20250213T111221.868Z/language/es.xml
// Adding dependency: /opt/whdata/installedmodules/nubiumreviews.20250213T111221.868Z/language/default.xml
// Adding dependency: /opt/whdata/installedmodules/nubiumreviews.20250213T111221.868Z/language/default.xml
// Adding dependency: /opt/whdata/installedmodules/nubiumreviews.20250213T111221.868Z/language/default.xml
