import "./booking.scss";

import * as dompack from "dompack";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";

dompack.onDomReady(() => {
  let date = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
  const minStay = 7;

  const fpArrival = flatpickr("#startDate", {
    altFormat: "d-m-Y",
    defaultDate: "today",
    minDate: "today",
  });
  const fpDeparture = flatpickr("#endDate", {
    altFormat: "d-m-Y",
    defaultDate: flatpickr.parseDate(
      date.toLocaleString("en-GB", { timeZone: "UTC" }),
      "d/m/Y h:i:s"
    ),
    minDate: fpArrival.formatDate(
      fpArrival.selectedDates[0].fp_incr(minStay),
      "Y-m-d"
    ),
  });

  fpArrival.config.onChange.push((dates) => {
    if (
      fpArrival.formatDate(fpArrival.selectedDates[0], "Y-m-d") >
      fpDeparture.formatDate(
        fpDeparture.selectedDates[0].fp_incr(-minStay),
        "Y-m-d"
      )
    ) {
      fpDeparture.setDate(dates[0].fp_incr(minStay));
    }
    fpDeparture.set(
      "minDate",
      fpArrival.formatDate(fpArrival.selectedDates[0].fp_incr(minStay), "Y-m-d")
    );
  });
});
