import * as dompack from "dompack";
import * as mso from "@mod-mso/webdesigns/mso/mso";

import "@mod-mso_templates/webdesigns/penitus/penitus";
import "./widgets/booking/booking";

import "./petit-natura.scss";
import "sweetalert2/dist/sweetalert2.min.css";

import "./petit-natura.lang.json";

dompack.onDomReady(() => {
  new mso.MSOSite();
});
